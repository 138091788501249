import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import SectionLayout from '../layouts/SectionLayout';
import SectionHeader from './CommonComps/SectionHeader';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

const Contact = () => {
  // rendering contact us section component here
  const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    // Handle form submission here
    console.log(values);
    setTimeout(() => {
      setSubmitting(false);
      resetForm();
    }, 1000);
  };

  return (
    <SectionLayout sectionId='contact' customClassName='bg-gray-100' fullWidth>
      <SectionHeader headerText='Get in touch' />

      <div className='w-[95%] md:w-4/5 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 mt-12 bg-base-white px-4 md:px-8 py-6 rounded-xl'>
        {/* Left Column */}
        <div className='space-y-3 flex flex-col justify-center'>
          <h3 className='font-montserrat font-bold text-2xl text-base-black'>
            Let's bring your ideas to life
          </h3>
          <p className='text-gray-600 leading-relaxed'>
            Let's resolve your inquiry. Simply fill out the form with your
            details, and we'll connect with you and find the right solution.
          </p>
        </div>

        {/* Right Column */}
        <div className='bg-white rounded-lg shadow-kirrhosoft-sm p-3 md:p-6'>
          <Formik
            initialValues={{
              name: '',
              email: '',
              subject: '',
              message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className='space-y-4'>
                <div>
                  <label htmlFor='name' className='text-base-light-gray p-1'>
                    Name
                  </label>
                  <Field
                    id='name'
                    type='text'
                    name='name'
                    placeholder='Enter Name'
                    className='w-full mt-1 placeholder:text-sm px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-base-green focus:border-transparent'
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label htmlFor='email' className='text-base-light-gray p-1'>
                    Email
                  </label>
                  <Field
                    id='email'
                    type='email'
                    name='email'
                    placeholder='Your Email'
                    className='w-full px-4 mt-1 py-2 placeholder:text-sm rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-base-green focus:border-transparent'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label htmlFor='subject' className='text-base-light-gray p-1'>
                    Subject
                  </label>
                  <Field
                    id='subject'
                    type='text'
                    name='subject'
                    placeholder='Subject'
                    className='w-full px-4 mt-1 py-2 placeholder:text-sm rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-base-green focus:border-transparent'
                  />
                  <ErrorMessage
                    name='subject'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label htmlFor='message' className='text-base-light-gray p-1'>
                    Message
                  </label>
                  <Field
                    id='message'
                    as='textarea'
                    name='message'
                    placeholder='Your Message'
                    className='w-full px-4 py-2 mt-1 placeholder:text-sm rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-base-green focus:border-transparent min-h-[100px]'
                  />
                  <ErrorMessage
                    name='message'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='w-full bg-white text-base-green border-base-green border hover:bg-base-green hover:text-white hover:scale-95  font-semibold py-4 px-4 rounded-lg transition duration-200 disabled:opacity-50'
                >
                  {isSubmitting ? 'Sending...' : 'Submit'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </SectionLayout>
  );
};

export default Contact;
