export const TESTIMONIAL_ITEMS: Array<Testimonial> = [
  {
    image: '/assets/images/fahad.jpg',
    name: 'Md. Fahad Hossain',
    designation: 'Manager, Accounts & Admin, KINDRED LTD.',
    comments: `Kirrhosoft's Outlet Management Software revolutionized our
                business. Its user-friendly interface streamlines
                purchases, sales tracking, and reporting. Real-time
                insights enhance outlet management, significantly boosting
                efficiency and customer satisfaction. Exceptional retail
                management software!`,
  },

  {
    image: '/assets/images/foysal.jpg',
    name: 'Shah Md. Foysal',
    designation: 'Manager, PORSHI LTD.',
    comments: `Thrilled with Kirrhosoft's Barcode Generator Software! It
                revolutionized our superstore inventory management, saving
                valuable time with its user-friendly interface and
                efficient barcode generation. Highly recommend for
                enhanced productivity and streamlined tracking.`,
  },

  {
    image: '',
    name: 'Umme Kulsum',
    designation: 'CEO, XYZ Lamination.',
    comments: `We've been using this software for a few months, and it's been a game changer for our business. The intuitive interface, reliable performance, and responsive support have exceeded our expectations. Highly recommended!`,
  },
];
