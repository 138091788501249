export const NAVBAR_ITEMS: Array<NavButton> = [
  //   {
  //     href: '#hero',
  //     label: 'Home',
  //   },

  {
    href: '#about',
    label: 'About',
  },

  {
    href: '#services',
    label: 'Services',
  },

  {
    href: '#work',
    label: 'Work',
  },

  {
    href: '#testimonials',
    label: 'Testimonials',
  },

  {
    href: '#contact',
    label: 'Contact',
  },
];
