import { WORK_ITEMS } from '../constants/works';
import SectionLayout from '../layouts/SectionLayout';
import SectionHeader from './CommonComps/SectionHeader';
import WorkCard from './CommonComps/WorkCard';

const Works = () => {
  return (
    <SectionLayout sectionId='work'>
      <SectionHeader headerText='Our Work' />

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
        {WORK_ITEMS.map((work, index) => (
          <WorkCard key={index} work={work} />
        ))}
      </div>
    </SectionLayout>
  );
};

export default Works;
