export const TECHNOLOGY_ITEMS: Array<Technology> = [
  {
    title: 'Python',
    icon: 'assets/techLogos/python.svg',
  },

  {
    title: 'Django',
    icon: 'assets/techLogos/django.svg',
  },

  {
    title: 'React',
    icon: 'assets/techLogos/react.svg',
  },

  {
    title: 'JavaScript',
    icon: 'assets/techLogos/javascript.svg',
  },

  {
    title: 'TypeScript',
    icon: 'assets/techLogos/typescript.svg',
  },

  {
    title: 'Next.js',
    icon: 'assets/techLogos/next.svg',
  },

  {
    title: 'HTML5',
    icon: 'assets/techLogos/html.svg',
  },

  {
    title: 'CSS3',
    icon: 'assets/techLogos/css.svg',
  },

  {
    title: 'Tailwind',
    icon: 'assets/techLogos/tailwind.svg',
  },

  {
    title: 'Django REST Framework',
    icon: 'assets/techLogos/drf.svg',
  },

  {
    title: 'FastAPI',
    icon: 'assets/techLogos/fast.svg',
  },

  {
    title: 'Flask',
    icon: 'assets/techLogos/flask.svg',
  },

  {
    title: 'GO Lang',
    icon: 'assets/techLogos/golang.svg',
  },

  {
    title: 'React Native',
    icon: 'assets/techLogos/react_native.svg',
  },

  {
    title: 'Flutter',
    icon: 'assets/techLogos/flutter.svg',
  },

  {
    title: 'Android',
    icon: 'assets/techLogos/android.svg',
  },

  {
    title: 'Kotlin',
    icon: 'assets/techLogos/kotlin.svg',
  },

  {
    title: 'Swift',
    icon: 'assets/techLogos/swift.svg',
  },

  {
    title: 'Vue.js',
    icon: 'assets/techLogos/vue.svg',
  },

  {
    title: 'Angular',
    icon: 'assets/techLogos/angular.svg',
  },

  {
    title: 'Selenium',
    icon: 'assets/techLogos/selenium.svg',
  },

  {
    title: 'Figma',
    icon: 'assets/techLogos/figma.svg',
  },

  {
    title: 'Photoshop',
    icon: 'assets/techLogos/photoshop.svg',
  },

  {
    title: 'Illustrator',
    icon: 'assets/techLogos/illustrator.svg',
  },
];
