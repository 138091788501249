import { SERVICE_ITEMS } from '../constants/services';
import SectionLayout from '../layouts/SectionLayout';
import SectionHeader from './CommonComps/SectionHeader';
import ServiceCard from './CommonComps/ServiceCard';

const Services = () => {
  // rendering services section component here
  return (
    <SectionLayout sectionId='services'>
      <SectionHeader headerText='Our Services' />

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5'>
        {SERVICE_ITEMS.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
    </SectionLayout>
  );
};

export default Services;
