import { FaFacebookSquare } from 'react-icons/fa';
import {
  FaLinkedin,
  FaLocationDot,
  FaPhone,
  FaSquareInstagram,
  FaXTwitter,
} from 'react-icons/fa6';
import { IoMdMail } from 'react-icons/io';

const Footer = () => {
  // rendering footer component here
  return (
    <footer className='bg-base-white'>
      <div className='w-[95%] md:w-4/5 mx-auto flex flex-col md:flex-row items-center justify-between gap-6 py-10'>
        <div className='flex flex-col md:flex-row items-center'>
          <div>
            <img
              className='size-40'
              src='assets/icons/k_soft_logo.svg'
              alt='Kirrhosoft logo'
            />
          </div>

          <div>
            <h1 className='font-bold text-3xl'>
              <span className='text-base-orange'>Kirrho</span>
              <span className='text-base-green'>soft</span>
            </h1>
          </div>
        </div>

        <div className='space-y-5'>
          <div className='space-y-2'>
            <div className='flex items-start gap-2'>
              <FaLocationDot className='size-6 text-base-orange' />
              <p>
                House-01, Road-04, <br /> Banani (DOHS), Dhaka-1206
              </p>
            </div>

            <div className='flex items-start gap-2'>
              <IoMdMail className='size-6 text-base-orange' />
              <p>info@kirrhosoft.com</p>
            </div>

            <div className='flex items-start gap-2'>
              <FaPhone className='size-6 text-base-orange' />
              <p>+8801301-036959</p>
            </div>
          </div>

          <div className='space-y-1 text-center'>
            <p>Connect with us:</p>
            <div className='flex items-center justify-center md:justify-start gap-3'>
              <a
                href='https://www.linkedin.com/company/Kirrhosoft-ltd/'
                className='hover:opacity-70 duration-300'
                target='_blank'
                rel='noreferrer'
              >
                <FaLinkedin className='size-8 text-base-orange' />
              </a>

              <a
                href='https://www.facebook.com/kirrhosoft/'
                className='hover:opacity-70 duration-300'
                target='_blank'
                rel='noreferrer'
              >
                <FaFacebookSquare className='size-8 text-base-orange' />
              </a>

              <a
                href='https://www.instagram.com/Kirrhosoft/'
                className='hover:opacity-70 duration-300'
                target='_blank'
                rel='noreferrer'
              >
                <FaSquareInstagram className='size-8 text-base-orange' />
              </a>

              <a
                href='https://x.com/Kirrhosoft_ltd'
                className='hover:opacity-70 duration-300'
                target='_blank'
                rel='noreferrer'
              >
                <FaXTwitter className='size-8 text-base-orange' />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='flex items-center justify-center text-sm py-1 bg-gray-100'>
        <span>
          © Copyright{' '}
          <span className='font-medium text-base-orange'>Kirrhosoft</span>. All
          Rights Reserved. {new Date().getFullYear()}.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
