import SectionLayout from '../layouts/SectionLayout';
import HighlightedText from './CommonComps/HighlightedText';

const Hero = () => {
  // rendering hero section component here
  return (
    <SectionLayout
      sectionId='hero'
      customClassName='flex flex-col lg:flex-row items-center justify-between gap-10'
    >
      <div className='w-full lg:w-1/2'>
        <div className='text-3xl md:text-5xl space-y-1 font-medium'>
          <h1>Grow beyond expectations</h1>
          <h1>
            with{' '}
            <span className='font-montserrat font-bold'>
              <span className='text-base-orange'>Kirrho</span>
              <span className='text-base-green'>soft</span>
            </span>
          </h1>
        </div>

        <div className='mt-5 text-base-light-gray space-y-2'>
          <p>
            Let's face it: managing a business is not an easy task. However
            small or large.
          </p>

          <p>
            At <HighlightedText text='Kirrhosoft' /> we build solutions that
            understands your needs as a business to make it easier to manage,
            operate and optimize. We build custom software using cutting-edge
            technology that is inline with the needs of a data driven and
            dynamic world, empowering your business to compete and sustain a
            healthy growth in tough markets.
          </p>

          <p>
            Our goal as a team is to craft software that seamlessly blends
            functionality and user experience. Elevate your business with our
            tailored solutions that redefine the the way you conduct your
            business.
          </p>

          <p className='!mt-5 font-semibold text-lg'>
            Let's improve lives, together.
          </p>
        </div>
      </div>

      <div className='flex-1'>
        <img src='assets/kirrho.webp' alt='working environment illustration' />
      </div>
    </SectionLayout>
  );
};

export default Hero;
