import { TESTIMONIAL_ITEMS } from '../constants/testimonials';
import SectionLayout from '../layouts/SectionLayout';
import SectionHeader from './CommonComps/SectionHeader';
import TestimonialCard from './CommonComps/TestimonialCard';

const Testimonials = () => {
  // rendering testimonials section component here
  return (
    <SectionLayout
      sectionId='testimonials'
      customClassName='bg-gray-100'
      fullWidth
    >
      <SectionHeader headerText='Testimonials' />

      <h2 className='font-montserrat text-center text-lg md:text-2xl italic -mt-5'>
        <q>Don't take our words. See what our customers says.</q>
      </h2>

      <div className='w-[95%] md:w-4/5 mx-auto mt-16 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-20 lg:gap-10'>
        {TESTIMONIAL_ITEMS.map((testimonial, index) => (
          <TestimonialCard key={index} testimonial={testimonial} />
        ))}
      </div>
    </SectionLayout>
  );
};

export default Testimonials;
