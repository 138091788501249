import { TECHNOLOGY_ITEMS } from '../constants/technologies';
import SectionLayout from '../layouts/SectionLayout';
import SectionHeader from './CommonComps/SectionHeader';
import TechCard from './CommonComps/TechCard';

const TechStack = () => {
  // rendering tech stack section component here
  return (
    <SectionLayout
      sectionId='tech-stack'
      customClassName='bg-gray-100'
      fullWidth
    >
      <SectionHeader headerText='Technologies We use' />

      <div className='w-4/5 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-6 mx-auto'>
        {TECHNOLOGY_ITEMS.map((tech, index) => (
          <TechCard key={index} technology={tech} />
        ))}
      </div>
    </SectionLayout>
  );
};

export default TechStack;
