import { NAVBAR_ITEMS } from '../constants/navbar';
import NavButton from './CommonComps/NavButton';

const Header = () => {
  // rendering the header component here
  return (
    <header className='sticky top-0 h-16 shadow-lg font-poppins text-base-black bg-base-white z-[99999]'>
      <nav
        id='navbar'
        className='w-[95%] md:w-4/5 h-full mx-auto flex items-center justify-between'
      >
        <a
          href='#hero'
          className='flex items-center justify-center font-montserrat'
        >
          <img
            className='size-14'
            src='assets/icons/k_soft_logo.svg'
            alt='Kirrhosoft logo'
          />

          <h1 className='font-bold text-2xl'>
            <span className='text-base-orange'>Kirrho</span>
            <span className='text-base-green'>soft</span>
          </h1>
        </a>

        <ul className='hidden md:flex items-center gap-3'>
          {NAVBAR_ITEMS.map((item, index) => (
            <NavButton key={index} href={item.href} label={item.label} />
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
