import SectionLayout from '../layouts/SectionLayout';
import SectionHeader from './CommonComps/SectionHeader';

const CEOProfile = () => {
  // rendering CEO Profile section component here
  return (
    <SectionLayout sectionId='CEO'>
      <SectionHeader headerText='Words from our founder & CEO' />

      <div className='flex flex-col lg:flex-row items-center gap-10'>
        <div className='space-y-1'>
          <div className='rounded-lg shadow-kirrhosoft-sm overflow-hidden md:h-[500px] lg:h-full'>
            <img
              className='h-auto w-full md:-mt-20 lg:-mt-5'
              src='assets/images/sajjadfx.jpg'
              alt='avatar of CEO'
            />
          </div>
        </div>

        <div className='space-y-2'>
          <p>
            My vision is simple yet profound: to elevate the local software
            industry by implementing international standards of quality and
            efficiency. Throughout my career, I noticed a recurring
            challenge—the gap between ambitious project goals and the realities
            of delivery. It became clear to me that the tech industry in
            Bangladesh needed a paradigm shift. I believe that with the right
            blend of talent, technology, and processes, we can not only meet but
            exceed client expectations.
          </p>

          <p>
            At our company, we strive to foster a culture of innovation,
            collaboration, and continuous improvement. We are committed to
            developing software solutions that are not only functional but also
            transformative for businesses. Our team is driven by a shared
            passion for excellence, and we approach every project with the same
            dedication and rigor that I learned over my years in the field.
          </p>

          <p>
            I envision a future where Bangladeshi software developers are
            recognized globally for their skills and creativity. Together, we
            will build a reputation for delivering high-quality software that
            meets the most demanding deadlines while ensuring a seamless
            experience for our clients.
          </p>

          <p>
            Thank you for being part of this journey. Together, we can drive
            change, push boundaries, and make a lasting impact on the technology
            landscape in Bangladesh and beyond. Let’s create a brighter future,
            one line of code at a time
          </p>

          <div className='!mt-3'>
            <div className='flex items-center gap-2'>
              <div className='h-1 w-4 bg-base-orange'></div>
              <p className=' text-2xl font-montserrat font-semibold'>
                Farhan Sajjad
              </p>
            </div>

            <div className='text-sm text-base-light-gray ml-6'>
              <span className='block'>Founder & CEO</span>
              <span>Kirrhosoft</span>
            </div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default CEOProfile;
