const TechCard = ({ technology }: { technology: Technology }) => {
  // destructuring the technology object here
  const { icon, title } = technology || {};

  // rendering technology card component here
  return (
    <div
      title={title}
      className='grayscale hover:grayscale-0 duration-500 cursor-pointer p-5'
    >
      <img className='h-full' src={icon} alt={`icon of ${title}`} />
    </div>
  );
};

export default TechCard;
