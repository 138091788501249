import SectionLayout from '../layouts/SectionLayout';
import HighlightedText from './CommonComps/HighlightedText';
import SectionHeader from './CommonComps/SectionHeader';

const About = () => {
  // rendering about us section component here
  return (
    <SectionLayout sectionId='about' customClassName='bg-gray-100' fullWidth>
      <SectionHeader headerText='about us' />

      <div className='w-[95%] md:w-4/5 mx-auto flex flex-col md:flex-row items-start justify-between gap-5 text-base-light-gray'>
        <div className='space-y-4 w-full md:w-1/2'>
          <p>
            <HighlightedText text='Kirrhosoft' /> is a spirited venture born
            from a collective passion for innovation and excellence in software
            development.
          </p>

          <p>
            Our founder is a veteran software engineer who, after working for
            decades in multiple countries, moved back to Bangladesh and started
            this company with a dream to bring about changes that he thought was
            missing in the local industry but was absolutely necessary to
            deliver quality software while meeting strict deadlines.
          </p>

          <p>
            In the heart of our startup journey, we find strength in our humble
            beginnings—a small but vibrant workspace, fueled by the dreams and
            experiences of a diverse team. Our roots may be young, but the
            experiences we bring are seasoned, spanning industries and honing
            our skills to perfection.
          </p>
        </div>

        <div className='hidden md:block h-[300px] w-[2px] rounded-full bg-gray-300'></div>

        <div className='space-y-4  w-full md:w-1/2'>
          <p>
            At <HighlightedText text='Kirrhosoft' />, we envision a future where
            high-quality software isn't just a goal but a standard. Our journey
            is driven by an unwavering resolve to uphold strict standards in
            every facet of development. From code architecture to user
            interfaces, we strive for excellence, embracing the challenges of
            the digital landscape with innovation and precision.
          </p>

          <p>
            <HighlightedText text='So what do we do differently?' /> We write
            tests. A lot of them. We run them continuously. We deploy
            continuosly. We design to scale from the get go. We meticulously
            review our code and stick to various coding standards. We think
            about security in every design and coding decision because secuirty
            is not an option but a necessicity. We build software to improve
            lives and help our clients succeed, no matter what their goal is.
          </p>
        </div>
      </div>
    </SectionLayout>
  );
};

export default About;
