import { FaUser } from 'react-icons/fa';

const TestimonialCard = ({ testimonial }: { testimonial: Testimonial }) => {
  // destructuring the testimonial object here
  const { image, name, designation, comments } = testimonial || {};

  // rendering testimonial card component here
  return (
    <div className='relative bg-base-white px-5 pb-5 rounded-lg shadow-kirrhosoft-sm'>
      {image ? (
        <figure className='absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 size-20 rounded-full overflow-hidden border-2 border-base-orange'>
          <img className='object-cover' src={image} alt={`avatar of ${name}`} />
        </figure>
      ) : (
        <figure className='absolute flex items-center justify-center bg-base-white left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 size-20 rounded-full overflow-hidden border-2 border-base-orange'>
          <FaUser className='size-12 text-gray-300' />
        </figure>
      )}

      <figcaption className='text-center mt-12 space-y-4'>
        <div>
          <h3 className='text-xl font-medium'>{name}</h3>
          <span className='text-base-light-gray text-sm'>{designation}</span>
        </div>

        <div className='font-montserrat italic'>
          <q>{comments}</q>
        </div>
      </figcaption>
    </div>
  );
};

export default TestimonialCard;
