const SectionHeader = ({ headerText }: { headerText: string }) => {
  // rendering section header component here
  return (
    <div className='flex items-center justify-center mb-10'>
      <div className='relative flex items-center justify-center px-10'>
        <h1 className='text-center font-montserrat font-bold text-3xl uppercase'>
          {headerText}
        </h1>

        <div className='absolute top-[110%] h-1 w-2/5 bg-base-orange'></div>
      </div>
    </div>
  );
};

export default SectionHeader;
