export const SERVICE_ITEMS: Array<Service> = [
  {
    icon: 'assets/icons/r_n_d.png',
    title: 'Research & Development',
    description:
      'Innovative solutions tailored to your business needs, driving progress through advanced research and cutting-edge technology.',
  },

  {
    icon: 'assets/icons/outsourcing.png',
    title: 'Outsourcing',
    description:
      'Maximize efficiency and reduce costs by leveraging our expert teams for specialized tasks and projects.',
  },

  {
    icon: 'assets/icons/consulting.png',
    title: 'Consulting',
    description:
      'Strategic insights and expert guidance to enhance your business operations and drive growth.',
  },

  {
    icon: 'assets/icons/ui_ux.png',
    title: 'UI/UX Design',
    description:
      'Creating intuitive and engaging interfaces that elevate user experiences and boost satisfaction.',
  },

  {
    icon: 'assets/icons/websites.png',
    title: 'Websites',
    description:
      'Professionally designed websites that reflect your brand, attract visitors, and convert leads.',
  },

  {
    icon: 'assets/icons/web_app.png',
    title: 'Web Applications',
    description:
      'Custom web applications built for performance, scalability, and seamless user interaction.',
  },

  {
    icon: 'assets/icons/android_app.png',
    title: 'Android Applications',
    description:
      'Robust Android apps designed to engage users and meet your business objectives effectively.',
  },

  {
    icon: 'assets/icons/ios_app.png',
    title: 'IOS Applications',
    description:
      'High-quality iOS apps that provide a smooth user experience and align with Apple’s standards.',
  },

  {
    icon: 'assets/icons/custom_soft.png',
    title: 'Custom Software',
    description:
      'Tailored software solutions that address your unique challenges and enhance operational efficiency.',
  },

  {
    icon: 'assets/icons/saas.png',
    title: 'SaaS',
    description:
      'Flexible Software as a Service solutions that empower your business with scalable, cloud-based technology.',
  },

  {
    icon: 'assets/icons/testing.png',
    title: 'Software Testing',
    description:
      'Comprehensive testing services to ensure your software is reliable, secure, and user-friendly.',
  },

  {
    icon: 'assets/icons/devops.png',
    title: 'DevOps & Maintenance',
    description:
      'Streamlining operations for faster delivery and maintenance of services to keep your software up-to-date.',
  },
];
