const NavButton = ({ label, href }: NavButton) => {
  // rendering nav button component here
  return (
    <li>
      <a className='hover:text-base-orange duration-300' href={href}>
        {label}
      </a>
    </li>
  );
};

export default NavButton;
