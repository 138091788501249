const WorkCard = ({ work }: { work: Work }) => {
  // destructuring the work object here
  const { image, title, description } = work || {};

  // rendering work card component here
  return (
    <div className='group relative shadow-kirrhosoft-sm rounded-lg overflow-hidden cursor-pointer'>
      <figure>
        <img
          className='h-[350px] w-full object-fill group-hover:blur-sm duration-300'
          src={image}
          alt={`figure of ${title} project`}
        />
      </figure>

      <figcaption className='absolute h-full w-full left-0 top-[85%] border-t-2 border-base-orange text-center p-3 group-hover:top-1/2 bg-base-white/80 duration-300'>
        <div className='space-y-3'>
          <h2 className='font-montserrat text-2xl font-semibold'>{title}</h2>

          <p className='text-base-light-gray'>{description}</p>
        </div>
      </figcaption>
    </div>
  );
};

export default WorkCard;
