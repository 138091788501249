const ServiceCard = ({ service }: { service: Service }) => {
  // destructuring the service object here
  const { icon, title, description } = service || {};

  // rendering service card component here
  return (
    <div className='group relative rounded-lg text-center shadow-kirrhosoft-sm duration-300 cursor-pointer overflow-hidden'>
      <div className='relative p-3 space-y-3 z-10'>
        <div className='flex items-center justify-center'>
          <img className='w-16' src={icon} alt={`icon for ${title}`} />
        </div>

        <h2 className='font-medium text-xl text-base-orange'>{title}</h2>

        <p className='text-base-light-gray text-sm'>{description}</p>
      </div>

      <div className='absolute h-[500px] w-[1000px] top-0 -left-6 rounded-full bg-base-light-green group-hover:-left-56 duration-300'></div>
    </div>
  );
};

export default ServiceCard;
