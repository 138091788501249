export const WORK_ITEMS: Array<Work> = [
  {
    image: 'assets/images/east_west.png',
    title: 'Recruiting Management System',
    description:
      'Manages applicant tracking, interviews, business processes, documents, and expatriation processes for efficient talent acquisition for a major overseas recruitment office in Bangladesh.',
  },

  {
    image: 'assets/images/appointment.png',
    title: 'Legal Consulting Management System',
    description:
      'Organizes client cases, appointments, document management, financial reports, accounting and billing for a legal consultancy service based out of Canada.',
  },

  {
    image: 'assets/images/ee_lamination.png',
    title: 'Lamination Factory Software',
    description:
      'Oversees production workflows, inventory, accounting, and business reports for lamination processes in a small manufacturing setup.',
  },

  {
    image: 'assets/images/KindredDebitCredit.png',
    title: 'Outlet Management Software',
    description:
      'A web application for purchase and sales tracking with outlet-specific dashboards for users and admins. Enables detailed reporting and management of transactions per outlet for an established bakery chain.',
  },

  {
    image: 'assets/images/Porshi-bar-code.png',
    title: 'Barcoded Stock & Batch Management',
    description:
      'The web application to create and manage products/batches using barcode to simplify inventory management for a chain grocery store for efficient product tracking and custom label printing.',
  },

  {
    image: 'assets/images/saas.webp',
    title: 'SaaS Platform',
    description:
      'Our SaaS platform is currently under development with microservice architecture, designed to streamline business operations with powerful tools, intuitive interfaces, and a seamless user experience. Stay tuned for updates!',
  },
];
